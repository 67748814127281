<template>
  <el-row type="flex" align="middle" class="nav-header">
    <el-col :span="8" class="flex items-center">
      <router-link to="/" class="flex items-center">
        <img class="nav-logo" src="@/assets/layout/nav_logo.png" alt="logo" />
        <span class="title">{{ projectName }}</span>
      </router-link>
    </el-col>

    <el-col :span="16">
      <ul class="flex items-center justify-end">
        <li class="Notifications">
          <div @click="handleNotificationsClick">
            {{ $t('通知提醒') }}
            <el-badge v-if="noticeRemindTotalNums" :value="noticeRemindTotalNums" size="mini"></el-badge>
          </div>
          <Notifications ref="Notifications" />
        </li>
        <li v-if="sysBusinessConfigData && sysBusinessConfigData.ifAutoPrint">
          <el-popover
            ref="DownloadCenterPopover"
            placement="bottom-start"
            width="800"
            trigger="hover"
            popper-class="favorites-popper"
            @show="handleJbpPrintPopoverShow"
          >
            <jbpPrint
              v-if="jbpPrintData.length > 0"
              :jbpPrintData="jbpPrintData"
              :jbpPrintTemplates="printParamsObj"
              :jbpCacheKey="jbpCacheKey"
            />
            <el-badge class="item" slot="reference" style="cursor: pointer" :value="jbpPrintData.length || 0" :max="99">
              <span>
                <i class="iconfont mg-icon_dayin_wubiankuang" style="vertical-align: -1px" />
                {{ $t('聚宝盆打印') }}
              </span>
            </el-badge>
          </el-popover>
        </li>
        <li class="downloadCenter">
          <el-popover
            ref="DownloadCenterPopover"
            placement="bottom-start"
            width="500"
            trigger="hover"
            popper-class="favorites-popper"
            @show="handleDownloadCenterPopoverShow"
            @hide="handleDownloadCenterPopoverHide"
          >
            <DownloadCenter @callPopoverShow="callPopoverShow" ref="DownloadCenter" />
            <span slot="reference" style="cursor: pointer">
              <i class="iconfont mg-icon_daochu_wubiankuang" style="vertical-align: -1px" />
              {{ $t('下载中心') }}
            </span>
          </el-popover>
        </li>
        <li class="favorites" v-permission:favorites="actionBarRoute">
          <el-popover placement="bottom-start" width="500" trigger="hover" popper-class="favorites-popper">
            <Favorites />
            <span slot="reference" style="cursor: pointer">
              <i class="iconfont mg-icon_shoucangjia" style="vertical-align: -1px" />
              {{ $t('收藏夹') }}
            </span>
          </el-popover>
        </li>
        <li><select-lang></select-lang></li>
        <li>
          <el-dropdown @command="handleCommand">
            <span class="flex items-center">
              <img class="nav-avatar mr-5" src="@/assets/layout/nav_avatar.png" alt="avatar" />
              {{ employeeName }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="keyboardShortcuts">{{ $t('快捷键') }}</el-dropdown-item>
              <el-dropdown-item command="clearLocalCache">{{ $t('清除缓存') }}</el-dropdown-item>
              <el-dropdown-item command="changePassword">{{ $t('修改密码') }}</el-dropdown-item>
              <el-dropdown-item command="PDADownload">{{ $t('PDA下载') }}</el-dropdown-item>
              <el-dropdown-item command="retailDownload">{{ $t('零售下载') }}</el-dropdown-item>
              <el-dropdown-item command="logout" divided>{{ $t('退出登录') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </el-col>
    <el-dialog
      :title="$t('PDA下载')"
      width="400px"
      append-to-body
      top="25vh"
      @opened="onDialogOpened"
      :close-on-click-modal="false"
      :visible.sync="downloadDialogVisible"
    >
      <div ref="qrcode" class="pda-download-qrcode" />
    </el-dialog>
    <template v-if="visible">
      <ResetPassword :visible.sync="visible" />
    </template>
    <el-dialog
      :title="$t('零售下载')"
      width="600px"
      append-to-body
      top="25vh"
      :close-on-click-modal="false"
      :visible.sync="retailDownloadDialogVisible"
    >
      <el-row>
        <el-col :span="24" class="hrefTitle">
          <a
            style="color: #2976ec"
            href="https://daohang-retail-oss.oss-cn-shanghai.aliyuncs.com/daohang-retail-prod/%E6%99%A8%E5%85%89%E6%96%87%E5%85%B7-windows-2.0.5x32.exe"
          >
            晨光文具零售系统-windows32
          </a>
        </el-col>
        <el-col :span="24" class="hrefTitle">
          <a
            style="color: #2976ec"
            href="https://daohang-retail-oss.oss-cn-shanghai.aliyuncs.com/daohang-retail-prod/%E6%99%A8%E5%85%89%E6%96%87%E5%85%B7-windows-2.0.5.exe"
          >
            晨光文具零售系统-windows64
          </a>
        </el-col>
        <el-col :span="24" class="hrefTitle">
          <a
            style="color: #2976ec"
            href="https://daohang-retail-oss.oss-cn-shanghai.aliyuncs.com/daohang-retail-prod/Win7%E7%B3%BB%E7%BB%9F%E5%BE%AE%E8%BD%AFTTS%E8%AF%AD%E9%9F%B3%E4%BF%AE%E5%A4%8D_2018-02-06.exe"
          >
            TTS语音修复
          </a>
        </el-col>
        <el-col :span="24" class="hrefTitle">
          <a
            style="color: #2976ec"
            href="https://sh-oss-mg.oss-cn-shanghai.aliyuncs.com/dh-pos-updater/prod/%E6%99%A8%E5%85%89%E6%96%87%E5%85%B7%E6%96%B0%E9%9B%B6%E5%94%AE%E7%B3%BB%E7%BB%9F.exe"
          >
            新零售-windows64
          </a>
        </el-col>
      </el-row>
    </el-dialog>

    <mg-print-preview append-to-body ref="printPreviewRef" @handlePrintSuccess="handlePrintSuccess" />
  </el-row>
</template>
<script>
  import i18n from '@/locale'
  import { debounce } from 'lodash-es'
  import { mapActions, mapGetters } from 'vuex'
  import { ChangePwdStorage } from '@/utils/storage'
  import { projectName } from '@/config'
  import { GlobalEventType } from '@/constants'
  import { getPDADownloadPath } from '@/api/config'
  import { printMixin } from '@/mixins'
  import { actionTimer } from '@/utils'
  import { postJpbPrintData } from '@/api/wholesale/sales'
  import timerAction from '@/utils/timer'
  export default {
    name: 'Header',
    computed: {
      ...mapGetters({
        employeeName: 'user/employeeName',
        belongCode: 'user/belongCode',
        employeeNo: 'user/employeeNo',
        noticeRemindTotalNums: 'notice/noticeRemindTotalNums',
      }),
      actionBarRoute() {
        return this.$router.match('/actionbar')
      },
      jbpCacheKey() {
        return [this.belongCode, 'SALE_ORDER', this.employeeNo].join('_')
      },
    },
    components: {
      ResetPassword: () => import('@/components/reset-password'),
      SelectLang: () => import('@/components/lang-select'),
      Favorites: () => import('@/components/favorites'),
      DownloadCenter: () => import('@/components/download'),
      JbpPrint: () => import('@/components/jbpPrint'),
      Notifications: () => import('@/components/notifications'),
    },
    mixins: [printMixin],
    data() {
      return {
        projectName: i18n.t(projectName),
        visible: false,
        downloadUrl: null,
        downloadDialogVisible: false,
        retailDownloadDialogVisible: false,
        jbpPrintData: [],
        sysBusinessConfigData: null,
      }
    },
    async mounted() {
      const isNeedUpdatePwd = ChangePwdStorage.get()
      if (isNeedUpdatePwd) {
        setTimeout(() => {
          this.$mgAlert(
            i18n.t('密码已过期或密码强度较低，账号存在风险，请及时修改您的登录密码保障资料安全'),
            i18n.t('安全提示'),
            {
              showCancelButton: false,
              showClose: false,
              closeOnPressEscape: false,
            }
          ).then(() => {
            this.visible = isNeedUpdatePwd
          })
        })
      }
      getPDADownloadPath().then((res) => {
        this.downloadUrl = res.data.upgradePath
      })
      // 根据系统配置是否显示聚宝盆打印入口
      this.sysBusinessConfigData = await this.fetchSysBusinessConfig({ params: { businessGroupCode: '*' } })
      if (this.sysBusinessConfigData?.ifAutoPrint) {
        // 聚宝盆打印模板
        this.handleJbpPrintTemplates()
        // 聚宝盆打印数据
        this.handleJbpPrintActionTimer()
      }
    },
    beforeDestroy() {
      if (this.qrcode) {
        this.qrcode = null
      }
    },
    methods: {
      ...mapActions({
        logout: 'user/logout',
        fetchSysBusinessConfig: 'sys/getSysBusinessConfig',
      }),
      callPopoverShow() {
        this.$refs.DownloadCenterPopover.doShow()
        setTimeout(() => {
          this.$refs.DownloadCenterPopover.doClose()
        }, 10000)
      },
      handleDownloadCenterPopoverShow: debounce(function () {
        this.$refs.DownloadCenter.getDownloadList()
      }, 300),
      handleDownloadCenterPopoverHide: debounce(function () {
        this.$refs.DownloadCenter.handleClearInterval()
      }, 300),
      onDialogOpened() {
        if (!this.qrcode) {
          this.qrcode = new QRCode(this.$refs.qrcode, {
            width: 120,
            height: 120,
            colorDark: '#000000',
            useSVG: false,
            correctLevel: 0,
          })
          this.qrcode.makeCode(this.downloadUrl)
        }
      },
      handleCommand(command) {
        switch (command) {
          case 'logout':
            this.$mgAlert(
              i18n.t('您确定要退出{projectName}吗?', { projectName: this.projectName }),
              i18n.t('温馨提示'),
              {
                type: 'warning',
              }
            )
              .then(() => {
                return this.logout()
              })
              .then(() => {
                window.location.reload('/login')
              })
            break
          case 'changePassword':
            this.visible = true
            break
          case 'keyboardShortcuts':
            this.$root.$emit(GlobalEventType.KEYBOARD_SHORTCUTS)
            break
          case 'clearLocalCache':
            this.$mgConfirm(i18n.t('确定清除本地缓存?'), i18n.t('提示')).then(() => {
              LocalForage.clear()
            })
            break
          case 'PDADownload':
            this.downloadDialogVisible = true
            break
          case 'retailDownload':
            this.retailDownloadDialogVisible = true
            break
        }
      },
      // 聚宝盆打印 -- 获取打印模板
      handleJbpPrintTemplates() {
        const printParams = {
          billType: 'SALE_ORDER',
          billIdField: 'saleOrderId',
          codeJointNames: [{ name: 'customerName', code: 'customerCode' }],
          formatDateFields: ['createTime'],
          barcodeField: 'billNo',
        }
        this.handlePrintTemplateData(printParams)
      },
      // 聚宝盆打印 -- 获取需要打印的数据
      async handleJbpPrintActionTimer() {
        try {
          const { data } = await postJpbPrintData({})
          this.jbpPrintData = data
          actionTimer('jbpRemotePrint', this.handleJbpPrintActionTimer, 5000)
        } catch(err) {
          const { data } = err
          if (data?.code === 4011155) timerAction.clearTimer('jbpRemotePrint')
        }
      },
      // 聚宝盆打印 -- 显示弹窗时监听
      handleJbpPrintPopoverShow() {
        // 聚宝盆打印模板
        this.handleJbpPrintTemplates()
      },
      handleNotificationsClick() {
        this.$refs.Notifications.drawer = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .nav-header {
    height: 100%;
    .nav-logo {
      height: 20px;
      margin-right: 10px;
    }
    .nav-avatar {
      width: 20px;
    }
    .title {
      font-size: 15px;
    }
    ul li {
      margin-left: 10px;
    }
  }
  .pda-download-qrcode {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 120px;
  }

  ::v-deep {
    .downloadCenter {
      .el-popover {
        padding: 0 !important;
      }
    }
    .hrefTitle {
      font-size: 14px;
      margin: 10px 0;
    }
    .hrefStyle {
      margin: 15px 0;
    }
  }
  .Notifications {
    span {
      color: #e60012;
    }
  }
  ::v-deep {
    .el-badge__content {
      background-color: #e60012;
    }
  }
</style>
